.slide-right-base {
	visibility: hidden;
	 -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); 
}

.slide-right-enter {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); 
    visibility: visible !important;
}

.slide-right-enter-active {
  visibility: visible !important;
	opacity: 1;
	-webkit-transform: translate(0, 0);
	        transform: translate(0, 0);
    -webkit-transition: 0.4s ease-out;
    transition: 0.4s ease-out;
}

.slide-right-enter-done {
  visibility: visible !important;
	opacity: 1;
	-webkit-transform: translate(0, 0);
	        transform: translate(0, 0);
}

.slide-right-exit {
    float: none;
    padding: 0.75rem 1rem;
    font-weight: 100;
    font-size: 2.5rem;
    -webkit-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
    visibility: visible !important;
}

.slide-right-exit-active {
	visibility: hidden !important;
}

.slide-right-exit-done {
    -webkit-transform: translate(-100%, 0);
            transform: translate(-100%, 0); 
    visibility: visible !important;
}




.slide-left-base {
    visibility: hidden;
     -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0); 
}

.slide-left-enter {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0); 
    visibility: visible !important;
}

.slide-left-enter-active {
  visibility: visible !important;
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    -webkit-transition: 0.4s ease-out;
    transition: 0.4s ease-out;
}

.slide-right-enter-done {
  visibility: visible !important;
    opacity: 1;
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
}

.slide-right-exit {
    float: none;
    padding: 0.75rem 1rem;
    font-weight: 100;
    font-size: 2.5rem;
    -webkit-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
    visibility: visible !important;
}

.slide-right-exit-active {
    visibility: hidden !important;
}

.slide-right-exit-done {
    -webkit-transform: translate(100%, 0);
            transform: translate(100%, 0); 
    visibility: visible !important;
}