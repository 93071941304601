:root {
	--mst-column-width: 80px;
	--mst-table-width: 2250px;
	--mst-table-num-columns: 26;
	--mst-table-margin-allowance: 30px;

	--mst-table-col-base-width: calc(var(--mst-table-width)/var(--mst-table-num-columns));
	--mst-table-col-margin-width: calc(var(--mst-table-margin-allowance)/var(--mst-table-num-columns));

	--mst-table-col-width: calc(var(--mst-table-col-base-width)) !important;;
}

.mst-table-outer-div {
	min-height:  175px;
	/*overflow-x:  visible;*/
	overflow-y:  auto;
	max-width: 100vw;
	/*min-width:  var(--mst-table-width);*/
}

.fixed-1 {
	width: var(--mst-table-col-width) !important;
	min-width: var(--mst-table-col-width) !important;
	max-width: var(--mst-table-col-width) !important;
}

.fixed-1-5 {
	width: calc(var(--mst-table-col-width) * 1.5) !important;
	min-width: calc(var(--mst-table-col-width) * 1.5) !important;
	max-width: calc(var(--mst-table-col-width) * 1.5) !important;
}


.fixed-2 {
	width: calc(var(--mst-table-col-width) * 2) !important;
	min-width: calc(var(--mst-table-col-width) * 2) !important;
	max-width: calc(var(--mst-table-col-width) * 2) !important;
}

.fixed-3 {
	width: calc(var(--mst-table-col-width) * 3) !important;
	min-width: calc(var(--mst-table-col-width) * 3) !important;
	max-width: calc(var(--mst-table-col-width) * 3) !important;
}

.fixed-4 {
	width: calc(var(--mst-table-col-width) * 4) !important;
	min-width: calc(var(--mst-table-col-width) * 4) !important;
	max-width: calc(var(--mst-table-col-width) * 4) !important;
}

.mst-title {
	position: sticky;
	top: 0;
	align-self: flex-start;
}

.mst-container {
	display:  flex;
	align-items:  stretch;
	align-content:  stretch;
}

.mst-row {
	display: flex;
	flex-flow:  row nowrap;
	align-items:  stretch;
	align-content:  stretch;
	/*width: 100%;*/
	flex-grow: 1;
}

.mst-col {
	display: flex;
	flex-direction: column;
	align-self:  stretch;
	flex-grow: 1;
}

.mst-cell {
	padding:  10px;
	word-wrap: break-word;
}