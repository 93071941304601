.modal {
    position: fixed;
    top: 0;
    left: 0;
    width:100%;

    background: rgba(0, 0, 0, 0.6);
}

.modal-main {
    position:fixed;
    background: white;
    width: 80%;

    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.modal-wide {
    max-width: 750px !important;
}

.messages-scroll {
    max-height: calc(-410px + 100vh - 3.5rem);
    min-height: calc(-410px + 100vh - 3.5rem);
    overflow-y: auto;
}

.messages-scroll-archived {
    max-height: calc(-353px + 100vh - 3.5rem);
    min-height: calc(-353px + 100vh - 3.5rem);
    overflow-y: auto;
}