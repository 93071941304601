:root {
	--navbar-height-mobile: 61px;
	--navbar-height-browser: 110px;
}


.material-info-panel {
	z-index: 1500;
	position: absolute !important;
	transition: 0.5s;
	border-left: 5px solid #CAD9F8;
}

@media (max-width: 768px) {
	.material-info-panel {
		height: calc(100vh - var(--navbar-height-mobile));
		max-height: calc(100vh - var(--navbar-height-mobile));
		min-height: calc(100vh - var(--navbar-height-mobile));
		overflow: auto;
		position:  absolute;
		top: var(--navbar-height-mobile);
	}
}

@media (min-width: 769px) {
	.material-info-panel {
		box-shadow: -60px 0px 60px rgba(128, 128, 128, 0.065);
	}
}


.material-info-panel-active {
	right: 0;
}


@media (max-width: 768px) {
	.material-info-panel-inactive {
		right: -100%;
		display:  none;
	}
}

@media (min-width: 769px) {
	.material-info-panel-inactive {
		right: -50%;
	}
}