@media (min-width: 992px){
  .onenhalf-col{
    -webkit-box-flex: 0;
    flex: 0 0 12.49999%;
    max-width: 12.49999%;
  }
  }
  
@media (min-width: 992px){
  .twonhalf-col{
    -webkit-box-flex: 0;
    flex: 0 0 20.83333%;
    max-width: 20.83333%;
  }
  }

@media (min-width: 351px){
  .fournhalf-col{
    -webkit-box-flex: 0;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  }

@media (min-width: 351px){
  .sevennhalf-col{
    -webkit-box-flex: 0;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  }


.fs1m {
    font-size: 1em;
}

.fs2m {
    font-size: 1.3em;
}

.label-lg-300 {
    font-weight: 300;
    font-size: 1em;
}

.ls-head {
    letter-spacing: 0.1em;
}

.fw-lt{
    font-weight: 100;
}

.img-info {
    margin: 0 0 7px 4px;
}

.txtc {
    text-align: center;
}

.readstate.collapsed .fa-envelope-open-o,
.readstate .fa-envelope-o {
  display: none;
  font-weight: 100;
}

.readstate.collapsed .fa-envelope-o,
.readstate .fa-envelope-open-o {
  display: inline-block;
  font-weight: 100;
}

.readstate .fa-envelope-open-o{
  color: lightgrey;
}

.readstate .fa-envelope-o:hover,
.readstate .fa-envelope-open-o:hover {
  color: #50a1ff;
}


.unread label{
    font-weight: 700;
    color: #555555;
}

.read label{
    font-weight: 400;
    color:#757575;
}

.bookmark.collapsed .fa-bookmark,
.bookmark .fa-bookmark-o {
  display: none;
  font-weight: 100;
}

.bookmark.collapsed .fa-bookmark-o,
.bookmark .fa-bookmark {
  display: inline-block;
  font-weight: 100;
}

.bookmark .fa-bookmark{
  color: #926dde;
}

.bookmark .fa-bookmark-o:hover,
.bookmark .fa-bookmark:hover {
  color: #926dde;
}

.right{
    float: right;
}

.accordion .noborder {
  border-top-color: #fff !important;
  border-right-color: #fff !important;
  border-bottom-color: #fff !important;
  border-left-color: #fff !important;
  margin-bottom: 16px;
}

.noborder {
border-bottom: 0px !important;}

a.redlink:hover {
  color:#ff4954;
  background-color: #fceeef;
}

.togbor{
  border: 1px solid #e9ecf0;
}


.site-listing .card-title {
  background-color: #eef4fc;
}

.site-listing .card-title a{
  padding: 8px 0px;
  background-color: #eef4fc;
}

.round { border-radius: 50%; }


.lh-1{
  line-height: 1;
}

.lh-2{
  line-height: 1.5;
}

.fullwidth {
  padding-left: 0;
  padding-right: 0;
}

.border-darker {
  border: 1px solid #e5e7e9;
}

.border-primary {
  border: 1px solid #50a1ff;
}

.cursor {
  cursor: pointer;
}


.custom-file-label2 {
  position: absolute;
  top: -0.5em;
  right: 0;
  left: 0;
  z-index: 1;
  font-weight: 300;
  background-color: #fff;
  border: 1px solid #757575;
}

  .custom-file-label2::after {
    position: absolute;
    top: -0.5em;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    padding: 0.375rem 0.75rem;
    color: #999999;
    background-color: #e9ecef;
    border-left: inherit;
}

.contrast .form-control {
    border: 1px solid #ffffff;
    border-top-color: #ffffff;
    border-top-style: solid;
    border-top-width: 0px;
    border-right-color: #ffffff;
    border-right-style: solid;
    border-right-width: 0px;
    border-bottom-color: #000000;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-left-color: #ffffff;
    border-left-style: solid;
    border-left-width: 0px;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-width: initial;
    border-image-outset: initial;
    border-image-repeat: initial;
    color: #757575;
}


.form-control::-moz-placeholder {
  color: #757575;
  opacity: 1; /* Firefox */
}

.form-control::-webkit-placeholder { /* Microsoft Edge */
  color: #757575;

}

.form-control::-ms-input-placeholder { /* Internet Explorer 10-11 */
 color: #757575;

}

.form-control::placeholder {
 color: #757575;

}

.btn-lg{
font-size: 1.1em;
}

.btn-outline-contrast {
  color: #757575;
  background-color: transparent;
  background-image: none;
  border-color: #757575; }
  .btn-outline-contrast:focus, .btn-outline-contrast.focus, .btn-outline-contrast:hover {
    color: #757575;
    -webkit-box-shadow: 0 0 0 0rem rgba(25, 25, 25, 0.5);
            box-shadow: 0 0 0 0rem rgba(25, 25, 25, 0.5); }
  .btn-outline-contrast.disabled, .btn-outline-contrast:disabled {
    color: #757575;
    background-color: transparent; }



.trucks{
  width: 23rem;
  font-size: 1.3em;
  border-bottom: 1px solid #aaaaaa;
  padding-bottom: 6px;
}

.trucks .dropdown-menu {
  width: 23rem;
  font-size: 0.9em;
  max-height: 400px;
  overflow-y: scroll;

}

.trucks .dropdown-item {
  color: #555555;
  font-weight: 100;
  line-height: 1.5;
}

.trkimg{
  width: 130px;
  height: 90px;
  object-fit: cover;
  object-position: center;
}


.nav-item .widebox{
  min-width: 15em;
}

.nav-item .widestbox{
  min-width: 18em;
}

.table-slim th, .table-slim td{
padding-top: 0.25rem;
padding-right: 0.75rem;
padding-bottom: 0.25rem;
padding-left: 0.75rem;
}

.surpl{
color: rgb(3, 187, 3);
font-weight: 600;
text-align: right;
}

.short{
color: rgb(206, 1, 1);
font-weight: 600;
text-align: right;
}

.strong{
font-weight: 600;}

.tr-bid td{
font-weight: lighter;
}

.tr-archived td{
text-decoration: line-through;
color: rgb(155, 155, 155);
}

.level-badge .badge-number {
    top: 0px;
}

.mapoverlay {
    position: absolute;
    margin-top: -1em;
    margin-left: 0.5em;
}

.map-btn {
    position: fixed;
    bottom: 2em;
}

.bg-brand {
background-color: #344ed0;
}

.text-brand {
color: #344ed0;
}

.btn-brand {
background-color: #344ed0;
}

.border-brand {
border: 1px solid #344ed0;
}

.close-badge {
    float: right;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-shadow: none;
    opacity: 1;
}




.btn:disabled:hover {
  box-shadow: none;
}

.dropdown-item.hover:hover {
  cursor:pointer;
}

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a {
  display: block;
}

#main {
  transition: margin-left .5s;
}

.marker {
  width: 1.25em;
}